<template>
  <VDialog
    v-model="showDialog"
    activator="parent"
    max-width="600px"
    :transition="false"
    attach="#app"
  >
    <template #default>
      <VCard>
        <VitalSignsPatientFilterContent @close-dialog="closeDialog" />
        <VCardActions class="justify-start w-full border-t border-divider !p-0 mt-2">
          <div class="flex items-center justify-between w-full p-8 pt-6">
            <span class="text-body-1">{{ numberOfCasesAfterFiltering }} {{ t('Results') }}</span>

            <div>
              <VBtn
                variant="text"
                color="primary"
                data-testid="btn-cancel"
                class="ml-auto button-large"
                size="large"
                :text="t('Close')"
                @click="closeDialog"
              />
              <VBtn
                variant="flat"
                color="primary"
                class="ml-4 button-large"
                size="large"
                :text="t('Apply')"
                data-testid="btn-confirm"
                :loading="isSubmitting"
                @click="onSubmit"
              />
            </div>
          </div>
        </VCardActions>
      </VCard>
    </template>
  </VDialog>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import VitalSignsPatientFilterContent from '@/components/patient/filter/VitalSignsPatientFilterContent.vue'
import { useVitalSignsFilterStore } from '@/stores/models/vitalSignsFilters'
import { useI18n } from 'vue-i18n'
import { FiltersApi } from '@/generated'
import { useUserStore } from '@/stores/user'
import { globalApiConfig } from '@/services/telehealthApi/APIConfig'
import { useCaseStore } from '@/stores/case'
import { storeToRefs } from 'pinia'

const { t } = useI18n()
const showDialog = ref<boolean>(false)
const isSubmitting = ref<boolean>(false)
const vitalSignsFilterStore = useVitalSignsFilterStore()
const userStore = useUserStore()
const caseStore = useCaseStore()
const { sortedCasesWithVitalSigns } = storeToRefs(caseStore)

const numberOfCasesAfterFiltering = computed<number>(() => {
  return sortedCasesWithVitalSigns.value.filter((caze) =>
    vitalSignsFilterStore.filterVitalSignsCases(caze, false)
  ).length
})

async function onSubmit() {
  try {
    isSubmitting.value = true
    vitalSignsFilterStore.commitVitalSignsFilterInput()

    const filtersApi = new FiltersApi(globalApiConfig)
    const encodedUsername = btoa(userStore.user?.userName || '')

    await filtersApi.postVitalSignsFilters(encodedUsername, {
      vitalSigns: [vitalSignsFilterStore.committedVitalSignsFilter]
    })

    closeDialog()
  } finally {
    isSubmitting.value = false
  }
}

function closeDialog() {
  showDialog.value = false
}
</script>
