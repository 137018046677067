import { defineStore } from 'pinia'
import type { UserProfile } from '@/stores/models/UserProfile'
import { computed, type Ref, ref } from 'vue'
import type { languages } from '@/i18n/i18n'
import { type HospitalDTO, ShiftTypeDTO, type WardDTO } from '@/generated'
import type { FirebaseOptions } from '@/global/FirebaseOptions'

export const useUserStore = defineStore('user', () => {
  const user: Ref<UserProfile | undefined> = ref(undefined)
  const getTitle = computed(() => user.value!.title ?? '')
  const getFullName = computed(() =>
    [user.value!.firstName, user.value!.lastName].filter((elm) => elm !== undefined).join(' ')
  )
  const getDisplayName = computed(() => {
    const displayName = [user.value!.title, user.value!.firstName, user.value!.lastName]
      .filter((elm) => elm !== undefined)
      .join(' ')

    return displayName.length !== 0 ? displayName : user.value?.userName
  })
  const hasSelectedShift = computed<boolean>(() => !!user.value!.currentShift)

  const isIncognito = computed<boolean>(() => user.value?.currentShift === ShiftTypeDTO.NO_SHIFT)

  const hasGodModeShift = computed<boolean>(
    () => user.value?.currentShift === ShiftTypeDTO.GOD_MODE
  )

  const hasChiefPhysicianShift = computed<boolean>(
    () => user.value?.currentShift === ShiftTypeDTO.CHIEF_PHYSICIAN
  )

  const isTheSameAsUser = (userName: string): boolean => {
    return user.value?.userName === userName
  }

  const notificationToken: Ref<string | undefined> = ref(undefined)
  const firebaseOptions: Ref<FirebaseOptions | undefined> = ref(undefined)
  const notificationTokenLoading: Ref<boolean> = ref(false)

  function setUser(newUser: UserProfile | undefined) {
    user.value = newUser
  }

  function setLanguage(language: languages) {
    user.value!.language = language
  }

  function setCurrentShift(newShift: ShiftTypeDTO | undefined) {
    user.value!.currentShift = newShift
  }

  function setWards(newWards: WardDTO[] | undefined) {
    user.value!.wards = newWards
  }

  function setHospitals(newHospitals: HospitalDTO[] | undefined) {
    user.value!.hospitals = newHospitals
  }

  function setMedicalFields(newMedicalFields: string[] | undefined) {
    user.value!.medicalFields = newMedicalFields
  }

  function setNotificationToken(token: string): void {
    notificationToken.value = token
  }

  function clearNotificationToken(): void {
    notificationToken.value = undefined
  }

  function setFirebaseOptions(options: FirebaseOptions): void {
    firebaseOptions.value = options
  }

  function setHasGodMode(hasGodMode: boolean): void {
    user.value!.hasGodMode = hasGodMode
  }

  function setIsChiefPhysician(isChiefPhysician: boolean): void {
    user.value!.isChiefPhysician = isChiefPhysician
  }

  function setNotificationTokenLoading(loading: boolean): void {
    notificationTokenLoading.value = loading
  }

  return {
    user,
    getTitle,
    getFullName,
    getDisplayName,
    hasSelectedShift,
    isIncognito,
    isTheSameAsUser,
    hasGodModeShift,
    hasChiefPhysicianShift,
    notificationToken,
    firebaseOptions,
    setUser,
    setLanguage,
    setCurrentShift,
    setHospitals,
    setWards,
    setMedicalFields,
    setNotificationToken,
    clearNotificationToken,
    setFirebaseOptions,
    setHasGodMode,
    setIsChiefPhysician,
    notificationTokenLoading,
    setNotificationTokenLoading
  }
})
