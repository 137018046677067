import { FiltersApi, type VitalSignsFiltersDTO } from '@/generated'
import { globalApiConfig } from '@/services/telehealthApi/APIConfig'
import { urlSafeBase64 } from '@/util/base64'

export class FiltersService {
  static readonly filtersApi = new FiltersApi(globalApiConfig)

  static async getVitalSignsFilters(username: string): Promise<VitalSignsFiltersDTO> {
    const encodedUserName = urlSafeBase64(username)
    return (await FiltersService.filtersApi.getVitalSignsFilters(encodedUserName)).data
  }

  static async postVitalSignsFilters(
    username: string,
    vitalSignsFiltersDTO: VitalSignsFiltersDTO
  ): Promise<VitalSignsFiltersDTO> {
    const encodedUserName = urlSafeBase64(username)
    return (
      await FiltersService.filtersApi.postVitalSignsFilters(encodedUserName, vitalSignsFiltersDTO)
    ).data
  }

  static async deleteVitalSignsFilters(username: string): Promise<void> {
    const encodedUserName = urlSafeBase64(username)
    await FiltersService.filtersApi.deleteVitalSignsFilters(encodedUserName)
  }
}
