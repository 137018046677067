<template>
  <div>
    <VRangeSlider
      :model-value="modelValue"
      @update:model-value="$emit('update:modelValue', $event)"
      :min="min"
      :max="max"
      :step="step"
      class="mt-4"
      show-ticks="always"
      :tick-size="4"
      thumb-label="always"
      :ticks="customTicks"
    >
      <template #tick-label="{ tick }">
        <span class="text-caption">{{ tick.value }}</span>
      </template>
    </VRangeSlider>

    <div
      v-if="showTickLabels"
      class="flex justify-space-between text-caption text-secondary-text border-t border-grey-lighten-4 pt-2 mx-2"
    >
      <span>0</span>
      <span>20</span>
      <span>40</span>
      <span>60</span>
      <span>80</span>
      <span>100</span>
    </div>

    <div v-if="showTickLabels">
      <span class="text-caption text-secondary-text ml-2">{{ t('Mortality Rate [%]') }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps<{
  modelValue: number[]
  min: number
  max: number
  step: number
  showTickLabels?: boolean
  customTicks?: number[]
}>()

defineEmits<{
  'update:modelValue': [value: number[]]
}>()
</script>

<style scoped>
:deep(.v-slider-track__tick-label) {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}

:deep(.v-slider-thumb__label) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
  font-weight: 500;
}

:deep(.v-slider-track__fill),
:deep(.v-slider-thumb__surface) {
  background: #206683;
}

:deep(.v-slider-track__tick) {
  background: transparent;
}
</style>
