<template>
  <div
    id="countdown-container"
    class="flex items-center"
    :class="[displayColorClass, typographyClass]"
  >
    <VIcon v-if="showIcon" icon="timer" class="mr-1 material-icons-outlined" size="16" />
    <span data-testid="display-time"> {{ formattedTime }} </span>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { DateTime, Duration } from 'luxon'
import { useI18n } from 'vue-i18n'
import type { UrgencyWithCountdown } from '@/util/UrgencyWithCountdown'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    urgency: UrgencyWithCountdown
    nextEscalationAt: string
    showIcon?: boolean
    dark?: boolean
    regular?: boolean
  }>(),
  { dark: false, regular: false, showIcon: true }
)

const endTime = computed<DateTime>(() => DateTime.fromISO(props.nextEscalationAt))

const intervalId = ref<any>(undefined)
const remainingDuration = ref(Duration.fromMillis(0))

const formattedTime = computed<string>(() => {
  if (remainingDuration.value.as('hours') >= 1) {
    return remainingDuration.value.toFormat(`h '${t('h')}' m '${t('min.')}'`)
  }
  return remainingDuration.value.as('minutes') < 1
    ? remainingDuration.value.toFormat(`s '${t('sec.')}'`)
    : remainingDuration.value.toFormat(`m '${t('min.')}' s '${t('sec.')}'`)
})

const colorClassByUrgencyType: Record<UrgencyWithCountdown, string> = {
  emergency: 'text-error',
  urgent: props.dark ? 'text-warning-dark' : 'text-warning',
  routine: 'text-secondary-text'
}

const displayColorClass = computed<string>(() => colorClassByUrgencyType[props.urgency])

const typographyClass = computed<string>(() => {
  return props.regular ? '' : 'countdown-typography'
})

onMounted(() => {
  calculateRemainingDuration()
  intervalId.value = setInterval(() => {
    calculateRemainingDuration()
  }, 1000)
})

function calculateRemainingDuration(): void {
  if (endTime.value > DateTime.now()) {
    remainingDuration.value = Duration.fromObject(endTime.value.diff(DateTime.now()).toObject())
  } else {
    clearInterval(intervalId.value)
  }
}
</script>

<style scoped>
.countdown-typography {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: 0.15px;
}
</style>
