/* c8 ignore file */
import { ShiftTypeDTO } from '@/generated'
import { AuthenticationService } from '@/services/AuthenticationService'
import { useUserStore } from '@/stores/user'
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

export async function globalRouterGuards() {
  await AuthenticationService.updateAccessToken()
}

export function hasUserGodModeShift(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const userStore = useUserStore()

  if (userStore.user?.currentShift === ShiftTypeDTO.GOD_MODE) {
    next('/')
  } else {
    next()
  }
}

export function hasUserChiefPhysicianShift(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const userStore = useUserStore()

  if (userStore.user?.currentShift !== ShiftTypeDTO.CHIEF_PHYSICIAN) {
    next('/')
  } else {
    next()
  }
}
