import type { GetVitalSignsResponse } from '@/generated'
import { VitalSignsApi } from '@/generated'
import { globalApiConfig } from '@/services/telehealthApi/APIConfig'

export class VitalSignsService {
  static readonly vitalSignsApi = new VitalSignsApi(globalApiConfig)

  static async getVitalSigns(caseId: string): Promise<GetVitalSignsResponse> {
    return (await VitalSignsService.vitalSignsApi.getVitalSigns(caseId)).data
  }
}
