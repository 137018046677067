import type { GetCaseResponse, GetCasesResponse, GetMedicalFieldsResponse } from '@/generated'
import { DefaultApi } from '@/generated'
import { globalApiConfig } from '@/services/telehealthApi/APIConfig'

export class APIService {
  static readonly defaultApi = new DefaultApi(globalApiConfig)

  static async getMedicalFields(): Promise<GetMedicalFieldsResponse> {
    return (await APIService.defaultApi.getMedicalFields()).data
  }

  static async getCases(): Promise<GetCasesResponse> {
    return (await APIService.defaultApi.getCases()).data
  }

  static async getCase(id: string): Promise<GetCaseResponse> {
    return (await APIService.defaultApi.getCase(id)).data
  }

  // FIXME: This is not used anywhere. Remove it?
  static async createEmergency(id: string): Promise<void> {
    await APIService.defaultApi.createEmergency(id)
  }
}
