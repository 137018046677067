<template>
  <div class="min-w-[700px]">
    <div
      class="grid grid-cols-5 gap-4 text-body-1 !text-xs text-secondary-text bg-grey-100 py-2 px-6 mt-2"
    >
      <div class="place-self-center flex items-center flex-col">
        <span class="font-semibold">{{ t('Lactate (mmol/L)') }}</span>
        <span>{{ LACTATE.measurementDate }}</span>
      </div>
      <div class="place-self-center flex items-center flex-col">
        <span class="font-semibold">{{ t('Potassium (mmol/L)') }}</span>
        <span>{{ K.measurementDate }}</span>
      </div>
      <div class="place-self-center flex items-center flex-col">
        <span class="font-semibold">{{ t('Hemoglobin (g/dl)') }}</span>
        <span>{{ THB.measurementDate }}</span>
      </div>
      <div class="place-self-center flex items-center flex-col">
        <span class="font-semibold">{{ t('GFR (mL/min)') }}</span>
        <span>{{ GFR.measurementDate }}</span>
      </div>
      <div class="place-self-center flex items-center flex-col">
        <span class="font-semibold">{{ t('PH') }}</span>
        <span>{{ PH.measurementDate }}</span>
      </div>
    </div>
    <div class="grid grid-cols-5 gap-4 text-body-1 !text-sm text-primary-text py-2 px-6">
      <span
        data-testid="lactate"
        class="px-2 py-1 bg-neutral-100 w-fit rounded place-self-center"
        >{{ LACTATE.value }}</span
      >
      <span
        data-testid="potassium"
        class="px-2 py-1 bg-neutral-100 w-fit rounded place-self-center"
        >{{ K.value }}</span
      >
      <span
        data-testid="hemoglobin"
        class="px-2 py-1 bg-neutral-100 w-fit rounded place-self-center"
        >{{ THB.value }}</span
      >
      <span data-testid="gfr" class="px-2 py-1 bg-neutral-100 w-fit rounded place-self-center">{{
        GFR.value
      }}</span>
      <span data-testid="ph" class="px-2 py-1 bg-neutral-100 w-fit rounded place-self-center">{{
        PH.value
      }}</span>
    </div>
    <DividerLine />
  </div>
</template>

<script setup lang="ts">
import DividerLine from '@/atoms/DividerLine.vue'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { VitalSignsKeys } from '@/components/patient/models/VitalSignsKeys'
import { findLatestValueByKey } from './VitalInfoTable.utils'
import type { VitalSignsWithTypeDTO } from '@/generated'

const { t } = useI18n()

const { laboratory } = defineProps<{
  laboratory: VitalSignsWithTypeDTO[]
}>()

const LACTATE = computed(() => findLatestValueByKey(laboratory, VitalSignsKeys.LAC))
const K = computed(() => findLatestValueByKey(laboratory, VitalSignsKeys.K))
const THB = computed(() => findLatestValueByKey(laboratory, VitalSignsKeys.THB))
const PH = computed(() => findLatestValueByKey(laboratory, VitalSignsKeys.PH))
const GFR = computed(() => findLatestValueByKey(laboratory, VitalSignsKeys.GFR))
</script>
