<template>
  <VCardTitle class="flex justify-space-between items-center p-0 border-b border-divider">
    <div class="text-h4 p-8">{{ t('Filter patients') }}</div>
    <VBtn
      icon="close"
      variant="plain"
      class="button-small mr-2"
      size="small"
      :ripple="false"
      data-testid="btn-close"
      @click="emits('close-dialog')"
    />
  </VCardTitle>

  <VCardItem class="px-8 py-0 pt-4">
    <span class="text-subtitle-1">
      {{ t('Hospital') }}
    </span>
    <VAutocomplete
      v-model="hospital"
      clear-icon="custom:clear20"
      :items="hospitalData"
      chips
      closable-chips
      multiple
      :placeholder="t('Please select...')"
    />
  </VCardItem>

  <VCardItem class="px-8 py-0">
    <span class="text-subtitle-1">
      {{ t('Stations') }}
    </span>
    <VAutocomplete
      v-model="stations"
      clear-icon="custom:clear20"
      :items="stationData"
      chips
      closable-chips
      multiple
      :placeholder="t('Please select...')"
    />
  </VCardItem>

  <VCardItem class="px-8 py-0">
    <span class="text-subtitle-1">
      {{ t('Departments') }}
    </span>
    <VAutocomplete
      v-model="departments"
      clear-icon="custom:clear20"
      :items="departmentData"
      chips
      closable-chips
      multiple
      :placeholder="t('Please select...')"
    />
  </VCardItem>

  <VCardItem class="px-8 py-0">
    <span class="text-subtitle-1">
      {{ t('Vital Signs') }}
    </span>

    <div class="flex gap-2 py-2 items-center flex-wrap">
      <VChip v-if="hasRangeFilter(sofaRange)" closable @click:close="clearRange('sofaRange')">
        {{ t('SOFA') }}: {{ formatRange(sofaRange) }}
      </VChip>

      <VChip v-if="hasRangeFilter(apacheRange)" closable @click:close="clearRange('apacheRange')">
        {{ t('APACHE II') }}: {{ formatRange(apacheRange) }}
      </VChip>

      <VChip
        v-if="hasRangeFilter(temperatureRange)"
        closable
        @click:close="clearRange('temperatureRange')"
      >
        {{ t('Temperature °C') }}: {{ formatRange(temperatureRange) }}
      </VChip>

      <VChip v-if="hasRangeFilter(laktateRange)" closable @click:close="clearRange('laktateRange')">
        {{ t('Laktat (mmol/l)') }}: {{ formatRange(laktateRange) }}
      </VChip>
    </div>

    <VExpansionPanels>
      <VExpansionPanel>
        <VExpansionPanelTitle>SOFA</VExpansionPanelTitle>
        <VExpansionPanelText>
          <MortalityRangeSlider
            v-model="sofaRange"
            :min="0"
            :max="10"
            :step="0.1"
            show-tick-labels
            :custom-ticks="[0, 2, 4, 6, 8, 10]"
          />
        </VExpansionPanelText>
      </VExpansionPanel>

      <VExpansionPanel>
        <VExpansionPanelTitle>APACHE II</VExpansionPanelTitle>
        <VExpansionPanelText>
          <MortalityRangeSlider
            v-model="apacheRange"
            :min="0"
            :max="40"
            :step="1"
            show-tick-labels
            :custom-ticks="[0, 10, 20, 30, 40]"
          />
        </VExpansionPanelText>
      </VExpansionPanel>

      <VExpansionPanel>
        <VExpansionPanelTitle>{{ t('Temperature °C') }}</VExpansionPanelTitle>
        <VExpansionPanelText>
          <MortalityRangeSlider
            v-model="temperatureRange"
            :min="30"
            :max="45"
            :step="1"
            :custom-ticks="[30, 45]"
          />
        </VExpansionPanelText>
      </VExpansionPanel>

      <VExpansionPanel>
        <VExpansionPanelTitle>{{ t('Laktat (mmol/l)') }}</VExpansionPanelTitle>
        <VExpansionPanelText>
          <MortalityRangeSlider v-model="laktateRange" :min="30" :max="45" :step="1" />
        </VExpansionPanelText>
      </VExpansionPanel>

      <VExpansionPanel>
        <VExpansionPanelTitle>{{ t('Organ Support') }}</VExpansionPanelTitle>
        <VExpansionPanelText>
          <div class="flex gap-4 items-center text-body-1">
            <VCheckbox v-model="dialysis" :label="t('Dialysis')" />
            <VCheckbox v-model="ventilation" :label="t('Ventilation')" />
            <VCheckbox v-model="ecmo" :label="t('ECMO')" />
            <VCheckbox v-model="impella" :label="t('Impella')" />
          </div>
        </VExpansionPanelText>
      </VExpansionPanel>
    </VExpansionPanels>
  </VCardItem>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useCaseStore } from '@/stores/case'
import { computed } from 'vue'
import type { SelectOption } from '@/util/SelectOption'
import type { CaseDTO, HospitalDTO } from '@/generated'
import MortalityRangeSlider from './MortalityRangeSlider.vue'
import { useVitalSignsFilterStore, type VitalSignsFilter } from '@/stores/models/vitalSignsFilters'

const emits = defineEmits(['close-dialog', 'apply-filter'])

const { t } = useI18n()
const caseStore = useCaseStore()
const { caseList } = storeToRefs(caseStore)
const vitalSignsFilterStore = useVitalSignsFilterStore()
const { vitalSignsFilterInput } = storeToRefs(vitalSignsFilterStore)

const stations = computed({
  get: () => vitalSignsFilterInput.value.stations || [],
  set: (value) => {
    vitalSignsFilterInput.value.stations = value
  }
})

const hospital = computed({
  get: () => vitalSignsFilterInput.value.hospital || [],
  set: (value) => {
    vitalSignsFilterInput.value.hospital = value
  }
})

const departments = computed({
  get: () => vitalSignsFilterInput.value.departments || [],
  set: (value) => {
    vitalSignsFilterInput.value.departments = value
  }
})

const sofaRange = computed({
  get: () => vitalSignsFilterInput.value.sofaRange?.map(Number) || [],
  set: (value) => {
    vitalSignsFilterInput.value.sofaRange = value
  }
})

const apacheRange = computed({
  get: () => vitalSignsFilterInput.value.apacheRange?.map(Number) || [],
  set: (value) => {
    vitalSignsFilterInput.value.apacheRange = value
  }
})

const temperatureRange = computed({
  get: () => vitalSignsFilterInput.value.temperatureRange?.map(Number) || [],
  set: (value) => {
    vitalSignsFilterInput.value.temperatureRange = value
  }
})

const laktateRange = computed({
  get: () => vitalSignsFilterInput.value.laktateRange?.map(Number) || [],
  set: (value) => {
    vitalSignsFilterInput.value.laktateRange = value
  }
})

const dialysis = computed({
  get: () => vitalSignsFilterInput.value.dialysis || false,
  set: (value) => {
    vitalSignsFilterInput.value.dialysis = value
  }
})

const ventilation = computed({
  get: () => vitalSignsFilterInput.value.ventilation || false,
  set: (value) => {
    vitalSignsFilterInput.value.ventilation = value
  }
})

const ecmo = computed({
  get: () => vitalSignsFilterInput.value.ecmo || false,
  set: (value) => {
    vitalSignsFilterInput.value.ecmo = value
  }
})

const impella = computed({
  get: () => vitalSignsFilterInput.value.impella || false,
  set: (value) => {
    vitalSignsFilterInput.value.impella = value
  }
})

const hospitalData = computed<SelectOption<HospitalDTO>[]>(() => {
  const uniqueHospitals: HospitalDTO[] = [
    ...new Map(
      caseList.value
        .map((caze: CaseDTO) => caze.hospital)
        .map((hospital) => [hospital.id, hospital])
    ).values()
  ]

  return uniqueHospitals.map((hospital: HospitalDTO) => {
    return {
      value: hospital,
      title: hospital.name
    }
  })
})

const stationData = computed<SelectOption<string>[]>(() => {
  return [1, 2, 3, 4].map((index) => ({
    value: `Station ${index}`,
    title: `Station ${index}`
  }))
})

const departmentData = computed<SelectOption<string>[]>(() => {
  return [1, 2, 3, 4].map((index) => ({
    value: `Department ${index}`,
    title: `Department ${index}`
  }))
})

export interface PatientFilter {
  hospitals: HospitalDTO[]
  stations: string[]
  departments: string[]
  organSupport: string[]
  sofaRange: number[]
  apacheRange: number[]
  temperatureRange: number[]
}

function hasRangeFilter(range: readonly (string | number)[] | undefined): boolean {
  return Array.isArray(range) && range.length === 2 && range.every((value) => value !== undefined)
}

function formatRange(range: readonly (string | number)[] | undefined): string {
  if (!range || !hasRangeFilter(range)) return ''
  return `${range[0]} - ${range[1]}`
}

function clearRange(rangeKey: keyof VitalSignsFilter) {
  if (vitalSignsFilterInput.value) {
    vitalSignsFilterInput.value[rangeKey] = undefined
  }
}
</script>
